import React from "react"
import parse from "html-react-parser"
import styles from "./TabProgetti.module.scss"
import AnimatedLink from "../AnimatedLink/AnimatedLink"

const TabProgetti = ({ data }) => {
  const lista_progetti = data.portfolio.edges.map((item, index) => {
    if (!item.node.acf.featured) {
      const slug = item.node.slug
      const titoloHtml = parse(item.node.acf.titolo)
      const descrizioneHtml = parse(item.node.acf.descrizione.substring(0, 200))
      const servizi = item.node.acf.link_correlati.map((item, index) => {
        return <div key={index}>{parse(item.post_title.toUpperCase())}</div>
      })
      return (
        <AnimatedLink to={`/portfolio/${slug}`} key={index}>
          <div className={"scrollChildren"}>
            <div key={item.node.id} className={styles.row}>
              <div className={`${styles.col} ${styles.col1}`}>
                <h3>{servizi}</h3>
              </div>
              <div className={`${styles.col} ${styles.col1}`}>
                <h2>{titoloHtml}</h2>
              </div>
              <div className={`${styles.col} ${styles.col2}`}>
                <h1>{descrizioneHtml}</h1>
              </div>
            </div>
          </div>
        </AnimatedLink>
      )
    }
  })
  return (
    <div className={[styles.table, styles.containerMedium].join(" ")}>
      <h4>{"Progetti Degni di nota".toUpperCase()}</h4>
      <div>
        <div className={"customScrollChildrenAppear customScrollAnimation"}>
          {lista_progetti}
        </div>
      </div>
    </div>
  )
}
export default TabProgetti
