import React from "react"
import Img from "gatsby-image"
import styles from "./BlockTestimonial.module.scss"

const BlockTestimonial = ({ data, index }) => {
  const quote = data.testimonial.quote
  const author = data.testimonial.autore
  const sottotitolo = data.testimonial.autore_sottotitolo
  const imgTestimonial = data.testimonial.immagine.localFile.childImageSharp
  return (
    <div
      className={[
        styles.layoutTestimonial,
        "customScrollChildrenAppear",
        "customScrollAnimation",
        "defaultVerticalSpace",
      ].join(" ")}
      key={index}
    >
      <div className={"scrollChildren"}>
        <p>{quote}</p>
      </div>
      <div className={styles.authorContainer + " scrollChildren"}>
        <div className={styles.thumbnail}>
          <Img fluid={imgTestimonial.fluid} />
        </div>
        <div className={styles.column4}>
          <h1>
            <strong>{author}</strong>
          </h1>
          <h1>{sottotitolo}</h1>
        </div>
      </div>
    </div>
  )
}
export default BlockTestimonial
