import React from "react"
import parse from "html-react-parser"
import style from "./Description.module.scss"

const Description = ({ desc }) => {
  const descHtml = parse(desc)
  return (
    <div className={`${style.description} ${style.column6}`}>
      <p>{descHtml}</p>
    </div>
  )
}
export default Description
