import React from "react"
import Description from "../components/Description/Description"
import Heading from "../components/Heading/Heading"
import styles from "./templateServizi.module.scss"
import ProjectList from "../components/ProjectList/ProjectList"
import TabProgetti from "../components/TabProgetti/TabProgetti"
import Header from "../components/Header/Header"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import SEO from "../components/seo"
import HeaderImg from "../components/HeaderImg/HeaderImg"
import Footer from "../components/Footer/Footer"

const TemplateServizi = props => {
  const { pageContext } = props
  const { acfServizi, progetti, meta } = pageContext
  const { titolo, sottotitolo, descrizione, gruppo_servizi } = acfServizi
  const { transitionStatus } = props
  const projectList = progetti.edges.filter(({ node: progetto }) => {
    return progetto.acf.link_correlati.find(
      servizio => servizio.post_title === titolo
    )
  })
  const serviziGroup = gruppo_servizi.map(e => e.servizio).join(" - ")
  const tabella = pageContext
  tabella["portfolio"] = tabella["progetti"]

  let transitionClass = "transitionContents " + transitionStatus

  return (
    <TransitionBehavior>
      <Header />
      <SEO meta={meta} />
      <div className={transitionClass}>
        <HeaderImg>
          <Heading
            title={titolo}
            subtitle={serviziGroup}
            ish1={true}
            align={"center"}
          ></Heading>
        </HeaderImg>
        <div
          className={[styles.descriptionContainer, styles.containerMedium].join(
            " "
          )}
        >
          <Description desc={descrizione} className={styles.column6} />
        </div>
        <div
          className={[styles.containerMedium, styles.listaServizi].join(" ")}
        >
          <ProjectList
            projectArray={projectList}
            testimonial={false}
            immagine={true}
            verticalList={true}
          />
        </div>
        <TabProgetti data={tabella} />
        <Footer />
      </div>
    </TransitionBehavior>
  )
}

export default TemplateServizi
