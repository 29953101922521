import React from "react"
import styles from "./ProjectList.module.scss"
import AnimatedLink from "../AnimatedLink/AnimatedLink"
import Heading from "../Heading/Heading"
import Img from "gatsby-image/withIEPolyfill"
import BlockTestimonial from "../BlocksElement/BlockTestimonial/BlockTestimonial"

export default function ProjectList({
  projectArray,
  testimonial,
  immagine,
  verticalList,
}) {
  let stylesContainer = [styles.projectListContainer]
  if (verticalList) {
    stylesContainer.push(styles.verticalList)
  }
  return (
    <div className={stylesContainer.join(" ")}>
      {projectArray.map(({ node: portfolio }, index) => {
        const immaginepresentazione =
          portfolio.acf.header.localFile.childImageSharp.fluid
        const titoloHtml = portfolio.acf.titolo
        const sottotitoloHtml = portfolio.acf.sottotitolo
        let testimonialArray1 = null
        if (portfolio.acf.layout_progetto !== null) {
          testimonialArray1 = portfolio.acf.layout_progetto.find(element => {
            return (
              element.__typename === "WordPressAcf_testimonial" &&
              element.testimonial.home === true
            )
          })
        }

        return (
          <div key={portfolio.id} className={styles.projectList}>
            <div className={styles.projectListItem}>
              <div className={styles.projectListItemRow}>
                {!immagine ? (
                  <div></div>
                ) : (
                  <div
                    className={[
                      styles.imageContainer,
                      "customScrollAnimation",
                      "customScrollFadeInUp",
                    ].join(" ")}
                  >
                    <AnimatedLink to={`/portfolio/${portfolio.slug}`}>
                      <Img
                        fluid={immaginepresentazione}
                        className={styles.fillImageForceful}
                        alt={titoloHtml}
                      />
                    </AnimatedLink>
                  </div>
                )}
                <div
                  className={[
                    styles.titleContainer,
                    "customScrollAnimation",
                    "customScrollChildrenAppear",
                  ].join(" ")}
                >
                  <AnimatedLink to={`/portfolio/${portfolio.slug}`}>
                    <Heading
                      title={titoloHtml}
                      subtitle={sottotitoloHtml}
                      ish1={false}
                      alignment={
                        verticalList && index % 2 === 0 ? "right" : "left"
                      }
                    ></Heading>
                  </AnimatedLink>
                </div>
              </div>
              {testimonial &&
              typeof testimonialArray1 !== "undefined" &&
              testimonialArray1 !== null ? (
                <div>
                  <BlockTestimonial data={testimonialArray1} />
                </div>
              ) : null}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ProjectList.defaultProps = {
  verticalList: false,
  testimonial: false,
}
